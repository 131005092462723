import styled from '@emotion/styled'
import { Card } from '@mui/material'
import { ContentContainerAppClient, PageTitle, mobileMediaQuery, spacing, useTranslate } from '@orus.eu/pharaoh'
import { trpcReact } from '../../client'
import { assert } from '../../lib/errors'
import { useSession } from '../../lib/session'
import { LegacyPaymentMethodCardBody } from '../molecules/card-payment-methods'

export default function PaymentMethodUpdate(): JSX.Element {
  const translate = useTranslate()
  const [paymentMethod] = trpcReact.paymentMethod.getMyPaymentMethod.useSuspenseQuery()

  const session = useSession()
  assert(session !== null, 'cannot access payment methods page without a session')

  return (
    <>
      <PageTitle title={translate('my_payment_method')} />
      <ContentContainerAppClient>
        <CardWithMarginOnMobile>
          <LegacyPaymentMethodCardBody paymentMethod={paymentMethod} />
        </CardWithMarginOnMobile>
      </ContentContainerAppClient>
    </>
  )
}

const CardWithMarginOnMobile = styled(Card)`
  ${mobileMediaQuery} {
    margin: ${spacing[70]} ${spacing[60]};
  }
`
